import * as React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


import { FaWirsindhandwerk } from "react-icons/fa6";
import Divider from '@mui/material/Divider';
import Contact from './contactmpower';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box className='tabsBox'>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="About" {...a11yProps(0)} />
          <Tab label="Leadership" {...a11yProps(1)} />
          <Tab label="Contact" {...a11yProps(2)} />

        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
    

<div className='svgContainer'>
<FaWirsindhandwerk />
</div>


<Divider/>

<p>MPower Leaders is a political and business consultancy based in South East Asia and  focused on ASEAN as well as, more widely, the Global South.

<br/><br/>

Our methodology has been developed by our team based on our more than 8 years of activity as trainers, facilitators or coaches on projects with a variety of organisations from political foundations to international business and civil society organisations. 


<br/><br/>


All across our clients' journey / campaign to achieve his or her objectives we prioritise feedback thereby helping them improve each step of the way. This way, along the journey, our client or partners perform better each time by getting real time help and the genuine feeling of partnership across the road to success. 

</p>



        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          

<div className='raduContainer'>

<img className='raduMagdin' src='images/radu-magdin-min.webp'></img>


</div>

<Divider/>



<p className='introText'>

Radu Magdin is a consultant, trainer and think tanker. He was a honorary advisor to Romanian and Moldovan Prime Ministers and he also worked in the past with MPs, MEPs and successful Presidential candidates. 

<br/><br/>


Furthermore, Radu is an analyst who is widely quoted in international media, has a PhD in Russian Information Warfare in Eastern Europe and is the author of the Friedrich Naumann's <a href='https://www.freiheit.org/sites/default/files/2021-02/newgraphics-full-digital-rgb-liberal-leadership-and-strategic-communications-in-the-covid-19-era.pdf' title='Radu Magdin Book' target='_blank'>Leadership and Strategic Communications Playbook</a>. 



<br/><br/>

Moreover, Radu also has a soul project, <a href='https://www.eli-global.org' title='Radu Magdin ELI Global Project' target='_blank'>ELI Global</a>, a project dedicated to his daughter.

<br/><br/>

You may connect with Radu on <a href='https://ro.linkedin.com/in/radumagdin' title='Radu Magdin LinkedIn' target='_blank'>LinkedIn</a>, <a href='https://www.facebook.com/radu.magdin/' title='Radu Magdin Facebook' target='_blank'>Facebook</a>,  <a href='https://twitter.com/radumagdin' title='Radu Magdin Twitter' target='_blank'>Twitter</a> and <a href='https://www.instagram.com/radumagdin/' title='Radu Magdin Instagram' target='_blank'>Instagram</a>.


</p>


        </TabPanel>
       


        <TabPanel value={value} index={2} dir={theme.direction}>
          

<Contact />

        </TabPanel>








      </SwipeableViews>
    </Box>
  );
}