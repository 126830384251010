import * as React from "react"
import { Link } from "gatsby"

import CookieNotice from "../components/cookienotice"
import Tabs from "../components/tabs"

import AppBar from '@mui/material/AppBar';
import { IoHomeSharp } from "@react-icons/all-files/io5/IoHomeSharp";






const Layout = ({ location, title, children }) => {


  return (
      
<div >





      <main>{children}



      </main>






<footer>

<div className='footerContainer'>

<Tabs />







             <div className='footerCopyright'>






        <span>© Radu Gheorghe Magdin. All rights reserved.</span>
  <Link to='privacy-policy'>Privacy Policy</Link>
<Link to='terms-and-conditions'>Terms</Link>

        </div>
</div>
</footer>













<CookieNotice />























  </div>
  )
}

export default Layout
